@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.font-sans {
  font-family: var(--font-sofiapro-regular-sans), sans-serif;
}
.font-serif {
  font-family: var(--font-sofiapro-regular-sans), sans-serif;
}

body {
  @apply font-serif font-light text-base md:text-lg text-neutral-700;

  a {
    @apply hover:text-colorOrange-600;
  }
  strong {
    @apply font-medium;
  }
}
body .gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
nav .navbar-menu a {
  @apply font-sans font-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-neutral-800;
  font-family: var(--font-sofiapro-bold-sans), sans-serif;
  line-height: 1.1em;
}
h1 {
  @apply text-5xl md:text-6xl lg:text-7xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4 mt-10;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl pb-3 pt-8;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}

.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.slider-sec:before {
  @apply bg-fixed bg-black bg-opacity-75 absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.accordion-features {
  button {
    @apply p-4;
  }
  h2 {
    @apply text-xl font-normal text-black;
  }
  div,
  p {
    @apply text-base;
  }
}

.accordion-benefits {
  button {
    @apply py-2 pl-0 ml-0 bg-transparent;
  }
  h2 {
    @apply text-2xl font-semibold text-black;
  }
}

.title-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.title-heading:before {
  @apply bg-fixed absolute  bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.parallex1 {
  @apply bg-cover bg-fixed bg-no-repeat bg-center relative overflow-hidden w-full;
}
.parallex1:before {
  @apply absolute bottom-0 bg-black bg-opacity-85 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.tint-gray {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.tint-gray:before {
  @apply bg-fixed absolute bottom-0 bg-gray-100 bg-opacity-90 left-0 right-0 top-0 w-full overflow-hidden transform;
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.embla__dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.5;
}

.embla__dot.is-selected {
  opacity: 1;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
/** accordion **/
.accordion-content {
  transition:
    max-height 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;
}

.services-home {
  .slick-track {
    display: flex;
    gap: 1rem;
  }
}

.tick-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-top: 10px;
}

.tick-list li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  color: #333;
}

.tick-list li::before {
  content: '✔'; /* You can replace this with any icon you want */
  position: absolute;
  left: 0;
  top: 2px;
  color: #28a745; /* Green checkmark */
  font-size: 24px;
  line-height: 1;
}

/* Optional additional styling */
.tick-list li:hover {
  color: #007bff; /* Change text color on hover */
}

.blog {
  /*  @apply tracking-wide leading-loose; */
  @apply text-xl leading-6;

  strong {
    @apply font-bold;
  }
  p {
    @apply my-4 text-xl;
  }
  h1 {
    @apply text-colorOrange-800 mb-2 text-3xl md:text-4xl lg:text-5xl;
  }
  h2 {
    @apply text-3xl md:text-4xl lg:text-5xl text-colorOrange-600 font-bold my-8 mt-12 pb-2 border-b-2 border-colorOrange-600;
  }
  h3 {
    @apply text-2xl md:text-2xl lg:text-3xl text-colorBlue-600 font-bold my-4;
  }
  h4 {
    @apply text-lg md:text-xl lg:text-2xl text-colorBlue-600 font-bold my-4;
  }

  hr {
    @apply my-8 border-b border-colorBlue-300;
  }

  pre {
    @apply p-6 my-3 bg-gray-200;
  }

  .table-of-contents {
    @apply bg-colorOrange-100 px-4 sm:px-10 py-6  my-4;

    legend {
      @apply text-2xl text-colorOrange-600 uppercase font-bold bg-white px-4 border border-colorOrange-600;
    }

    a {
      @apply text-colorOrange-800;
    }
  }

  li,
  ul,
  ol {
    @apply m-0 p-0;
  }

  ul {
    @apply list-disc pl-5; /* Adds bullets and padding for indentation */
  }

  ol {
    @apply list-decimal pl-5; /* Adds numbers and padding for indentation */
  }

  pre {
    text-wrap: auto;
  }

  .tips {
    @apply px-6 py-4 border-2 border-colorOrange-600 bg-colorOrange-200 my-8;

    legend {
      @apply text-xl text-colorOrange-600 uppercase font-bold bg-white px-4 border-2 border-colorOrange-600;
    }
  }

  .warnings {
    @apply px-6 py-4 border-2 border-colorRed-600 bg-colorRed-200  my-8;

    legend {
      @apply text-xl text-colorRed-600 uppercase font-bold bg-white px-4 border-2 border-colorRed-600;
    }
  }

  .facts {
    @apply px-6 py-4 border-2 border-colorBlue-600 bg-colorBlue-200 my-8;

    legend {
      @apply text-xl text-colorBlue-600 uppercase font-bold bg-white px-4 border-2 border-colorBlue-600;
    }
  }

  .statistics {
    @apply px-6 py-4 border-2 border-colorAqua-600 bg-colorAqua-200 my-8;

    legend {
      @apply text-2xl text-colorAqua-600 uppercase font-bold bg-white px-4 border-2 border-colorAqua-600;
    }
  }

  .did-you-know {
    @apply px-6 py-4 border-2 border-colorRed-600 bg-colorRed-200  my-8;

    legend {
      @apply text-2xl text-colorRed-600 uppercase font-bold bg-white px-4 border-2 border-colorRed-600;
    }
  }

  .faqs {
    @apply px-6 py-4 border-2 border-colorBlue-600 bg-colorBlue-200  my-8;

    h3 {
      @apply text-xl md:text-xl text-colorBlue-900 font-semibold my-4;
    }
    legend {
      @apply text-2xl text-colorBlue-600 uppercase font-bold bg-white px-4 border-2 border-colorBlue-600;
    }
  }

  .case-studies {
    @apply px-6 py-2 border-2 border-colorOrange-600 bg-colorOrange-200  my-8;

    h3 {
      @apply text-xl md:text-xl text-colorOrange-900 font-semibold my-4;
    }
    legend {
      @apply text-2xl text-colorOrange-600 uppercase font-bold bg-white px-4 border-2 border-colorOrange-600;
    }
  }

  .quotes {
    @apply px-6 py-2 border-2 border-colorAqua-600 bg-colorAqua-200 my-8;

    h3 {
      @apply text-xl md:text-xl text-colorAqua-900 font-semibold my-4;
    }
    legend {
      @apply text-2xl text-colorAqua-600 uppercase font-bold bg-white px-4 border border-colorAqua-600;
    }
  }

  .about-the-offer {
    @apply px-6 py-2 border-2 border-colorAqua-600 bg-colorAqua-200 my-8;

    h3 {
      @apply text-xl md:text-xl text-colorAqua-900 font-semibold my-4;
    }
    legend {
      @apply text-2xl text-colorAqua-600 uppercase font-bold bg-white px-4 border-2 border-colorAqua-600;
    }
  }

  .cta {
    @apply bg-colorOrange-200 text-xl px-4 sm:px-10 py-4 sm:pt-8 sm:pb-4 border-2 border-colorAqua-600 text-white leading-tight;

    h3 {
      @apply text-3xl text-white leading-tight font-bold;
    }
  }

  .highlight {
    @apply px-4 sm:px-10 py-4 sm:py-8;

    h3 {
      @apply text-lg text-colorYellow-600 mb-4 pt-0 mt-0 pb-4 border-b border-colorYellow-700 uppercase font-bold;
    }

    &.highlight-brown {
      @apply bg-colorYellow-100 text-base;

      &.darker {
        @apply bg-colorYellow-200;
      }
      h3 {
        @apply text-colorYellow-600;
      }
    }

    &.highlight-blue {
      @apply bg-colorBlue-100 text-base;

      &.darker {
        @apply bg-colorBlue-200;
      }
      h3 {
        @apply text-colorBlue-600;
      }
    }
  }

  .image-prompt {
    display: none;
  }
}
